/* You can add global styles to this file, and also import other style files */

@import 'theme/index.scss';

html, body { height: 100%; }
html{
    overscroll-behavior: none;
    overflow: hidden;
}
body { 
    margin: 0; 
    font-family: "Open Sans", sans-serif; 
    color: #666666;
    touch-action: none;  
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
}

body::-webkit-scrollbar {
    display: none;
  }

.mat-form-field {
    width: 100%;
}